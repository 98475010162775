import React from 'react'
import { Button } from 'react-bootstrap';

const aButtonTexts = ["Say it, foolish robot!", "Again, machine slave!", "I SAID AGAIN!", "AGAIN!!!!!", "Getting bored enough?", "Really...?", "Ok, bud, this Text isn't gonna change anymore."];
let aUtterances = [];

class ErrorPage extends React.Component {
  constructor() {
    super();
    this.state = {
      sFakeWords: Math.random().toString(36).substring(7) + ' ' + Math.random().toString(36).substring(7) + ' ' + Math.random().toString(36).substring(7),
      sButtonText: aButtonTexts[0],
      iCount: 0,
      bButtonDisabled: false,
      sButtonCursorType: "pointer"
    };
    this.onClickSayIt = this.onClickSayIt.bind(this);
  }
  onClickSayIt() {
    this.setState({bButtonDisabled: true, sButtonCursorType: 'auto'}); // immediately disable button, set cursor to standard browser cursor
    var oUtterance = new SpeechSynthesisUtterance();
    aUtterances.push(oUtterance);
    let sText;
    console.log(this.state.iCount);
    console.log(aButtonTexts.length);
    if (this.state.iCount < aButtonTexts.length - 1) {
      console.log("setting increment");
      sText = aButtonTexts[this.state.iCount + 1]; // get text at the current count
    } else { // keep the last text, telling the user it wont change!
      console.log("setting to last");
      sText = aButtonTexts[aButtonTexts.length - 1];
    }
    oUtterance.text = this.state.sFakeWords;
    speechSynthesis.speak(oUtterance);
    oUtterance.onend = () => {
      console.log("firing on end...");
      // update all vars (button back to active, cursor back to pointer)
      this.setState({sFakeWords: Math.random().toString(36).substring(7) + ' ' + Math.random().toString(36).substring(7) + ' ' + Math.random().toString(36).substring(7),
      sButtonText: sText,
      iCount: this.state.iCount + 1,
      bButtonDisabled: false,
      sButtonCursorType: 'pointer'});
    }
  }
  render () {
    return (
      <div>
        {/* Page Header */}
        <header className="masthead legal">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <div className="site-heading">
                  <h1>You NLP'd the crap out of our server! We couldn't find that page :(</h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Legal Content */}
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <h2>You can check the navigation above to get around, or waste some time here listening to a robot try to speak crazy words:</h2>
                <p className="text-center">{this.state.sFakeWords}</p>
                <div className="col-lg-12 text-center">
                  <Button bsStyle="success" className="btn" onClick={this.onClickSayIt} disabled={this.state.bButtonDisabled} style={{cursor:this.state.sButtonCursorType}}>{this.state.sButtonText}</Button>
                </div>
              </div>
           </div>
         </div>
      </div>
    );
  }
}

export default ErrorPage;
